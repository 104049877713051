import React, { Component } from "react";
import gsap from "gsap";
import { Howl } from "howler";
import jacksrbetterJson from "./jrbjson.json";
import resultCards from "./resultCards";
import historyCards from "../RussianPoker/UI/resultCards";
import popupExit from "../../../../../assets/common_imgs/exit_btn.png"
import "./index.css";


import tablePrt from "../../../../../assets/games/jacksorbetter/jsrbrtable_Prt.png";
import tableLnds from "../../../../../assets/games/jacksorbetter/jsrbrtable_Lnds.png";
import tableWds from "../../../../../assets/games/jacksorbetter/jsrbrtable_Wds.png";

import tablePrtbg from "../../../../../assets/games/jacksorbetter/jsrbrtable_Prtbg.png";
import tableLndsbg from "../../../../../assets/games/jacksorbetter/jsrbrtable_Lndsbg.png";
import tableWdsbg from "../../../../../assets/games/jacksorbetter/jsrbrtable_Wdsbg.png";

import betbtnPrtLnds from "../../../../../assets/games/jacksorbetter/betbtn1.png";
import foldbtnPrtLnds from "../../../../../assets/games/jacksorbetter/foldbtn.png";
import buybtnPrtLnds from "../../../../../assets/games/jacksorbetter/buybtn.png";

import betbtnWds from "../../../../../assets/games/jacksorbetter/betbtn2.png";
import foldbtnWds from "../../../../../assets/games/jacksorbetter/foldbtn2.png";
import buybtnWds from "../../../../../assets/games/jacksorbetter/buybtn2.png";

import balanceImg from "../../../../../assets/games/russianPoker/balancewindows.png";
import betImg from "../../../../../assets/games/russianPoker/betwindows.png";
import menuImg from "../../../../../assets/games/jacksorbetter/menu.png";
import usernameImg from "../../../../../assets/games/russianPoker/userid.png";
// import volumeImg from "../../../../../assets/games/jacksorbetter/volume.png";
import volumeImg from "../../../../../assets/games/russianPoker/Valume.png";
import mutesnds from "../../../../../assets/games/russianPoker/mute_snds.png"
import winImg from "../../../../../assets/games/russianPoker/winnericonwindows.png";
import informationIcon from "../../../../../assets/games/jacksorbetter/information.png";

import chip_10 from "../../../../../assets/games/russianPoker/Whitechip.svg";
import chip_25 from "../../../../../assets/games/russianPoker/black.png";
import chip_50 from "../../../../../assets/games/russianPoker/blue.png";
import chip_100 from "../../../../../assets/games/russianPoker/red.png";
import chip_500 from "../../../../../assets/games/russianPoker/yellow.png";
import chip_1000 from "../../../../../assets/games/russianPoker/Purple chip.svg"
import betincerementdecrement from "../../../../../assets/games/russianPoker/betincreament&decreament.png";

import emtyCard from "../../../../../assets/games/jacksorbetter/Asset 1.svg";

import pokerchipsnd from "././../../../../../assets/sounds/pokerchip_snd.mp3";
import betchipsnd from "././../../../../../assets/sounds/betoncard.mp3";
import playerWin from "../../../../../assets/sounds/successNew.mp3"
import selectCard from "../../../../../assets/sounds/selectcard.mp3";
import unselectCard from "../../../../../assets/sounds/unselectcard.mp3";

import PayoutInformation from "./PayoutInformation"
import CardsContainer from "./CardsContainer";

import { connect } from "react-redux";


let intialCardStatus = [
    { id: 0, src: emtyCard, value: "", hold: false },
    { id: 1, src: emtyCard, value: "", hold: false },
    { id: 2, src: emtyCard, value: "", hold: false },
    { id: 3, src: emtyCard, value: "", hold: false },
    { id: 4, src: emtyCard, value: "", hold: false }
]

const activeMenuOptionStatus = {
    help:"HELP",
    history:"HISTORY"

}
class JacksorBetter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isActiveWindowsMode: null,
            isActivePortraitMode: null,
            isActiveLandscapeMode: null,
            balanceDeductions: props.balance,
            count: 0,
            dealDrawBetButton: "Deal",
            dealDrawBetButtonNumber: 0,
            coinType: 1,
            autoHold: false,
            autoPlay: false,
            showAutoPlayLimits: false,
            autoPlayRoundLimit: 5,
            activeRound: 1,
            betchipincrementbtn: false,
            betchipdecreamentbtn: true,
            numberofrounds: 0,
            jacksorbettercards: intialCardStatus,
            jacksorBetterPayouts: [],
            dealResult: {},
            menuOptionContainer: false,
            activeMenuOption: activeMenuOptionStatus.help,
            errorPopup: false,
            errorPopupTitle: "",
            errorPopupInfo: "",
            betAmount: 0,
            winAmount: 0,



            isSoundOn:true,
            isPlayingChip: false,
            chipSound: null,



            autoHoldFreezButton: false,
            autoPlayFreezButton: false,
            dealDrawHideShowButton: true,
            betOneHideShowButton: true,
            betMaxHideShowButton: true,
            betChipsCover: false,




            menuButton: null,
            menuOption: null,
            userContainer: null,
            userIcon: null,
            userName: null,
            balanceContainer: null,
            betseContainer: null,
            winseContainer: null,
            informationButtonWnds: null,
            volumeButtonWnds: null,
            autoholdButton: null,
            autoplayButton: null,
            errorPopupContainer: null,
            userIdContainer: null,
            useIdNumber: null,
            betChipsContainer: null,
            betChipIncrementButton: null,
            betChipDecrementButton: null,
            betImageContainer: null,
            betChipImage: null,
            betChipText: null,
            betButton: null,
            dealDrawButton: null,
            betMaxButton: null,
            betButtonImages:null,
            gameButtonChipText: null,
            CardsContainer: null,
            cardsContainerCover: null,
            payoutContainer: null,
            menuOptionContainerCss:null,
            helpMainContainer:null,


        };

        this.CardsContainer = React.createRef();

        this.chipVal = [
            { src: chip_10, key: 1, label: "10", val: 10 },
            { src: chip_25, key: 1, label: "20", val: 20 },
            { src: chip_50, key: 1, label: "50", val: 50 },
            { src: chip_100, key: 1, label: "100", val: 100 },
            { src: chip_500, key: 1, label: "500", val: 500 },
            { src: chip_1000, key: 1, label: "1000", val: 1000 },
        ];

        // this.jacksorbettercards = [
        //     { id: 0, src: emtyCard, value: "", hold:false },
        //     { id: 1, src: emtyCard, value: "" ,hold:false},
        //     { id: 2, src: emtyCard, value: "" ,hold:false},
        //     { id: 3, src: emtyCard, value: "" ,hold:false},
        //     { id: 4, src: emtyCard, value: "" ,hold:false}
        // ]
    }


    componentDidMount() {
        const { childRef } = this.props;
        childRef(this);

        this.pokerchipsnd = this.initializeChipSound();
        this.betchipsnd = this.initializeBetSound();
        this.playerWin = this.initializePlayerWinSound();
        this.selectCard = new Howl({src: [selectCard],html5: true,});
        this.unselectCard = new Howl({src: [unselectCard],html5: true,});

        this.handleOrientationChange();
        window.addEventListener("resize", this.handleResize);
        this.setState({
            jacksorBetterPayouts: this.props.game_PayoutInfo,
            balanceDeductions: this.props.balance
        });
        
        if (this.props.jrb_chipValues) {
            let newChips = this.props.jrb_chipValues.split(",");
            console.log(newChips);
            console.log(newChips.length)
            let lblTxt = "";
            for (let i = 0; i < newChips.slice(0, 5).length; i++) {
                if (Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999) {
                    lblTxt = "K"
                } else if (Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999) {
                    lblTxt = "M"
                } else if (Number(newChips[i]) > 999999999) {
                    lblTxt = "B"
                }
                this.chipVal[i].val = Number(newChips[i])
                console.log(this.chipVal[i].val, lblTxt);
                //   this.checkChipValuerussian(Number(this.chipVal[i].val), lblTxt, i);
            }
            
        }
        
        this.showPreviousGameStatus();

    }

      initializeChipSound = () => {
        let chipSound = new Howl({
          src: [pokerchipsnd],
          html5: true, // Enable HTML5 Audio for mobile compatibility
        });
        this.setState({
          chipSound,
          isPlayingChip: false
        })
        return chipSound
      }

      playPokerChipSound = (chipSound) => {
        const { isPlayingChip, isSoundOn } = this.state;
        let currentSound = chipSound;
        if (isSoundOn) {
    
          if (isPlayingChip) {
            chipSound.stop();
          }
          if (!chipSound) {
            currentSound = this.initializeChipSound();
          }
    
          currentSound.play();
          this.setState({
            isPlayingChip: true
          })
        }
    
      };

        initializeBetSound = () => {
          try {
            const betSound = new Howl({
              src: [betchipsnd],
              html5: true, // Enable HTML5 Audio for mobile compatibility
            });
            this.setState({
              betSound,
              isPlayingBet: false,
            });
            return betSound;
          } catch (error) {
            console.error("Error initializing bet sound:", error);
            return null;
          }
        };

        playRemainingSounds = (chipSound) => {
            const { isPlayingBet, isSoundOn } = this.state;
            let currentSound = chipSound;
            if (isSoundOn) {
        
              // if (currentSound) {
              //   chipSound.stop();
              // }
              // if (!chipSound) {
              //   currentSound = this.initializeBetSound();
              // }
        
              currentSound.play();
              // this.setState({
              //   isPlayingBet: true
              // })
            }
        
          };


            initializePlayerWinSound = () =>{
          
              let winSound = new Howl({
                src: [playerWin],
                html5: true,
              });
             
              return winSound
              
              
            }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        this.pokerchipsnd.pause();
        this.betchipsnd.pause();
        this.playerWin.pause();

    }

    handleResize = () => {
        this.handleOrientationChange()
    }

    showPreviousGameStatus = () => {
        // check and handle gameState
        const cardsContainerCoverElement = document.getElementById("cardsContainerCover");
        // if (this.props.gameState.handId === this.props.gameState.oldHandId && this.props.gameState.handId !== undefined && this.props.gameState.response != undefined) {
        if (this.props.gameState.handId === this.props.gameState.oldHandId && this.props.gameState.handId !== undefined && this.props.gameState.response != undefined ||
            this.props.gameState.response?.autoPlay&&this.props.gameState.response.count > this.props.gameState.response.activeRound) {
            console.log("active previous game")

            for (let i = 0; i < this.props.gameState.response.cards.length; i++) {
                for (let j = 0; j < resultCards.length; j++) {
                    if (resultCards[j].rsc_val === this.props.gameState.response.cards[i]) {

                        this.setState((prevState) => ({
                            jacksorbettercards: prevState.jacksorbettercards.map((eachCard) => {
                                if (eachCard.id === i) {
                                    return { ...eachCard, src: resultCards[j].src, hold: this.props.gameState.response.autoHoldCards ? this.props.gameState.response.autoHoldCards[i] : false }
                                }
                                return eachCard
                            }),
                            dealResult: this.props.gameState.response
                        }))
                    }

                }

            }

            let selectedChip = 1
            this.props.jrb_chipValues.split(",").map((eachValue, i) => {
                if (Number(eachValue) === this.props.gameState.response.coinCount) {
                    selectedChip = i;
                }
            })


            console.log(this.props.jrb_chipValues.split(","), this.props.gameState.response.coinCount);


            cardsContainerCoverElement.style.display = "none";
            this.highletPayoutInformation(Number(this.props.gameState.response.coinType));
            this.setState({
                dealDrawBetButton: "Draw",
                dealDrawBetButtonNumber: 1,
                numberofrounds: this.state.numberofrounds + 1,
                betAmount: Number(this.props.gameState.response.betAmount),
                count: selectedChip
            });
            if (selectedChip === 0) {
                this.setState({ betchipincrementbtn: false, betchipdecreamentbtn: true, })
            } else if (selectedChip === 5) {
                this.setState({ betchipincrementbtn: true, betchipdecreamentbtn: false, })

            } else {
                this.setState({ betchipincrementbtn: false, betchipdecreamentbtn: false, })

            }


            if (this.props.gameState.response?.autoPlay) {
                if (this.props.gameState.response.count > this.props.gameState.response.activeRound) {
                    cardsContainerCoverElement.style.display = "block";
                    this.setState({
                        autoPlay: this.props.gameState.response.autoPlay,
                        autoHold: this.props.gameState.response.autoHold,
                        autoPlayRoundLimit: this.props.gameState.response.count,
                        activeRound: this.props.gameState.response.activeRound,
                        betChipsCover: true,
                        betOneHideShowButton: false,
                        betMaxHideShowButton: false,
                        autoHoldFreezButton: true,
                        dealDrawHideShowButton: false,

                    })

                }
            }

            switch (this.props.gameState.response?.action) {
                case "PARAM_BET":
                    if (this.props.gameState.response.autoHoldCards) {
                        this.setState({ autoHold: true, })
                    }

                    if (this.props.gameState.response?.autoPlay) {
                        if (this.props.gameState.response.count > this.props.gameState.response.activeRound) {
                            setTimeout(() => {
                                this.clickOnDrawButton();
                            }, 2000);

                        }
                    } else {
                        this.setState({
                            betChipsCover: true,
                            betOneHideShowButton: false,
                            betMaxHideShowButton: false,
                            autoPlayFreezButton: true,
                        })

                    }
                    break;
                case "KEEP_CARDS":
                    if (this.props.gameState.response.autoPlay) {
                        if (this.props.gameState.response.count > this.props.gameState.response.activeRound) {
                            this.setState({
                                betChipsCover: true,
                                betOneHideShowButton: false,
                                betMaxHideShowButton: false,
                                autoHoldFreezButton: true,
                                autoPlayFreezButton: true,
                                dealDrawHideShowButton: false

                            })
                            setTimeout(() => {
                                this.clickOnDealButton(true);
                            }, 2000)
                        } else {
                            this.setState({
                                dealDrawBetButton: "Deal",
                                dealDrawBetButtonNumber: 0,
                                autoPlay: false
                            })
                        }
                    }
                    break
                default:
                    return null
            }



        } else {

            this.highletPayoutInformation(1);

            this.setState({
                numberofrounds: 0,
                betAmount: Number((this.state.coinType * this.chipVal[this.state.count].val).toFixed(2))
            })
            console.log("start new game  : ", this.state.coinType,this.state.count, this.chipVal[this.state.count].val)
            cardsContainerCoverElement.style.display = "block";

        }

    }


    handleOrientationChange = () => {

        // Detect if the platform is Windows
        const isActiveWindowsMode =
            (navigator.userAgentData && navigator.userAgentData.platform && navigator.userAgentData.platform === "Windows") ||
            navigator.userAgent.indexOf("Windows") !== -1;

        // Determine if the device is in portrait or landscape mode
        const isActivePortraitMode = window.innerHeight > window.innerWidth;
        const isActiveLandscapeMode = window.innerWidth > window.innerHeight;

        // Update the state
        this.setState({
            isActiveWindowsMode,
            isActivePortraitMode: !isActiveWindowsMode && isActivePortraitMode, // Only mobile devices will have portrait/landscape
            isActiveLandscapeMode: !isActivePortraitMode && isActiveLandscapeMode,
        });


        const jacksorBetterBgContainerElement = document.getElementById("jacksorBetterBgContainer");
        const jacksorbetterTableElement = document.getElementById("jacksorbetterTable");



        jacksorBetterBgContainerElement.style.cssText = "";
        jacksorbetterTableElement.style.cssText = "";

        Object.assign(jacksorBetterBgContainerElement.style, jacksrbetterJson.bgWindows);

        if (isActiveWindowsMode) {
            Object.assign(jacksorbetterTableElement.style, jacksrbetterJson.tableWnds);

            jacksorBetterBgContainerElement.style.backgroundImage = `url(${tableWdsbg})`;

            jacksorbetterTableElement.style.backgroundImage = `url(${tableWds})`;


            this.setState({
                menuButton: jacksrbetterJson.menuButtonWnds,
                menuOption: jacksrbetterJson.menuOption,
                userContainer: jacksrbetterJson.userContainer,
                userIcon: jacksrbetterJson.userIcon,
                userName: jacksrbetterJson.userName,
                balanceContainer: jacksrbetterJson.balanceContainer,
                betseContainer: jacksrbetterJson.betseContainer,
                winseContainer: jacksrbetterJson.winseContainer,
                informationButtonWnds: jacksrbetterJson.informationButtonWnds,
                volumeButtonWnds: jacksrbetterJson.volumeButtonWnds,
                autoholdButton: "autoholdButton",
                autoplayButton: "autoplayButton",
                errorPopupContainer: "errorPopupContainerwindows",
                userIdContainer: jacksrbetterJson.userIdContainer,
                useIdNumber: jacksrbetterJson.useIdNumber,
                betChipsContainer: jacksrbetterJson.betChipsContainer,
                betChipIncrementButton: jacksrbetterJson.betChipIncrementButton,
                betChipDecrementButton: jacksrbetterJson.betChipDecrementButton,
                betImageContainer: jacksrbetterJson.betImageContainer,
                betChipImage: jacksrbetterJson.betChipImage,
                betChipText: jacksrbetterJson.betChipText,
                betButton: jacksrbetterJson.betButton,
                dealDrawButton: jacksrbetterJson.dealDrawButton,
                betMaxButton: jacksrbetterJson.betMaxButton,
                betButtonImages:"",
                gameButtonChipText: jacksrbetterJson.gameButtonChipText,
                CardsContainer: jacksrbetterJson.CardsContainer,
                cardsContainerCover: jacksrbetterJson.cardsContainerCover,
                payoutContainer: "payoutContainer",
                menuOptionContainerCss:"menuOptionContainer",
                helpMainContainer:"helpMainContainer",
            })


        } else if (!isActiveWindowsMode && isActivePortraitMode) {
            Object.assign(jacksorbetterTableElement.style, jacksrbetterJson.tablePrt);

            jacksorBetterBgContainerElement.style.backgroundImage = `url(${tablePrtbg})`;
            jacksorbetterTableElement.style.backgroundImage = `url(${tablePrt})`;

            this.setState({
                menuButton: jacksrbetterJson.menuButtonPrt,
                menuOption: jacksrbetterJson.menuOption,
                userContainer: jacksrbetterJson.userContainerPrt,
                userIcon: jacksrbetterJson.userIcon,
                userName: jacksrbetterJson.userName,
                balanceContainer: jacksrbetterJson.balanceContainerPrt,
                betseContainer: jacksrbetterJson.betseContainerPrt,
                winseContainer: jacksrbetterJson.winseContainerPrt,
                informationButtonWnds: jacksrbetterJson.informationButtonWnds,
                volumeButtonWnds: jacksrbetterJson.volumeButtonPrt,
                autoholdButton: "autoholdButtonPrt",
                autoplayButton: "autoplayButtonPrt",
                errorPopupContainer: "errorPopupContainerwindows errorPopupContainerPrt",
                userIdContainer: jacksrbetterJson.userIdContainerPrt,
                useIdNumber: jacksrbetterJson.useIdNumber,
                betChipsContainer: jacksrbetterJson.betChipsContainerPrt,
                betChipIncrementButton: jacksrbetterJson.betChipIncrementButton,
                betChipDecrementButton: jacksrbetterJson.betChipDecrementButton,
                betImageContainer: jacksrbetterJson.betImageContainer,
                betChipImage: jacksrbetterJson.betChipImage,
                betChipText: jacksrbetterJson.betChipTextLnd,
                betButton: jacksrbetterJson.betButtonPrt,
                dealDrawButton: jacksrbetterJson.dealDrawButtonPrt,
                betMaxButton: jacksrbetterJson.betMaxButtonPrt,
                betButtonImages:"betButtonImagesPrt",
                gameButtonChipText: jacksrbetterJson.gameButtonChipTextPrt,
                CardsContainer: jacksrbetterJson.CardsContainerPrt,
                cardsContainerCover: jacksrbetterJson.cardsContainerCoverPrt,
                payoutContainer: "payoutContainerPrt",

                menuOptionContainerCss:"menuOptionContainerPrt",
                helpMainContainer:"helpMainContainerPrt",

                // menuOptionContainerCss:"menuOptionContainer",
                // helpMainContainer:"helpMainContainer",


            })


        } else if (!isActivePortraitMode && isActiveLandscapeMode) {
            Object.assign(jacksorbetterTableElement.style, jacksrbetterJson.tableLnds);

            jacksorBetterBgContainerElement.style.backgroundImage = `url(${tableLndsbg})`;
            jacksorbetterTableElement.style.backgroundImage = `url(${tableLnds})`;


            this.setState({
                menuButton: jacksrbetterJson.menuButtonWnds,
                menuOption: jacksrbetterJson.menuOption,
                userContainer: jacksrbetterJson.userContainerLnd,
                userIcon: jacksrbetterJson.userIcon,
                userName: jacksrbetterJson.userName,
                balanceContainer: jacksrbetterJson.balanceContainerLnd,
                betseContainer: jacksrbetterJson.betseContainerLnd,
                winseContainer: jacksrbetterJson.winseContainerLnd,
                informationButtonWnds: jacksrbetterJson.informationButtonWnds,
                volumeButtonWnds: jacksrbetterJson.volumeButtonLnd,
                autoholdButton: "autoholdButtonLnd",
                autoplayButton: "autoplayButtonLnd",
                errorPopupContainer: "errorPopupContainerwindows",
                userIdContainer: jacksrbetterJson.userIdContainerPrt,
                useIdNumber: jacksrbetterJson.useIdNumberLnd,
                betChipsContainer: jacksrbetterJson.betChipsContainerLnd,
                betChipIncrementButton: jacksrbetterJson.betChipIncrementButton,
                betChipDecrementButton: jacksrbetterJson.betChipDecrementButton,
                betImageContainer: jacksrbetterJson.betImageContainer,
                betChipImage: jacksrbetterJson.betChipImage,
                betChipText: jacksrbetterJson.betChipTextLnd,
                betButton: jacksrbetterJson.betButtonLnd,
                dealDrawButton: jacksrbetterJson.dealDrawButtonLnd,
                betMaxButton: jacksrbetterJson.betMaxButtonLnd,
                betButtonImages:"betButtonImagesPrt",
                gameButtonChipText: jacksrbetterJson.gameButtonChipTextPrt,
                CardsContainer: jacksrbetterJson.CardsContainerLnd,
                cardsContainerCover: jacksrbetterJson.cardsContainerCoverLnd,
                payoutContainer: "payoutContainerLnd",
                menuOptionContainerCss:"menuOptionContainer",
                helpMainContainer:"helpMainContainer",

            })


        }








    }



    showHideMenuOption = () => {
        console.log("click on menu option");

        let isMenuOption = !this.state.menuOptionContainer;
        this.setState((prevState) => {
            return {
                menuOptionContainer: isMenuOption
            }

        }, () => {
            if (isMenuOption) {
                this.playRemainingSounds(this.selectCard)
                console.log(document.getElementsByClassName(".menuOptionContainer button"))
                gsap.from([".menuOptionContainer button",".menuOptionContainerPrt button"], {
                    x: -500,
                    duration: 0.5,
                    ease: "power2.inOut",
                    zIndex: 12,
                    autoAlpha: 1,
                    stagger: 0.3
                })


                gsap.from([".helpMainContainer",".helpMainContainerPrt"], {
                    x: 2000,
                    duration: 1,
                    ease: "power2.inOut",
                    zIndex: 12,
                    autoAlpha: 1,
                    stagger: 0.3
                })
            }else{
                this.playRemainingSounds(this.unselectCard)
            }


            
        })

    }

    chooseAoptionInMenu = (option) => {
        this.playRemainingSounds(this.selectCard)
        this.setState({ activeMenuOption: option })
    }

    showDataBasedActiveMenuOption = () => {
        const { activeMenuOption } = this.state
        switch (activeMenuOption) {
            case activeMenuOptionStatus.help:
                return this.showHelpOption();
            case activeMenuOptionStatus.history:
                return this.showHistoryOption();
            default:
                return null;
        }
    }


    showHelpOption = () => (

        <div id="GameHelpContent">
            <h2>How To Play</h2>
            <ol>
                <li>Select the coin denomination with which you would like to play.</li>
                <li>Click <b>Bet One</b> to cycle between 1 and 5 coins bet per deal.</li>
                <li>Click <b>Bet Max</b> to bet and immediately deal a 5 coins bet.</li>
                <li>Click <b>Deal</b>.</li>
                <li>Click the cards to hold for that hand.</li>
                <li><b>Auto Hold</b> will by default select the optimum strategy for you.</li>
                <li>You may switch <b>Auto Hold</b> on/off by clicking the <b>Auto Hold</b> button prior to Deal.</li>
                <li>Click <b>Draw</b> to replace the cards you did not hold.</li>
                <li>If you win, the winning payline on the paytable will flash, indicating the hand and the coins won.</li>
                <li>Click <b>Deal</b> after the game has completed to repeat your previous bet.</li>
            </ol>

            <h2>Game Options</h2>
            <ol>
                <li>Click <b>Auto Play</b> and select the number of hands to autoplay.</li>
                <li>You can click <b>Stop Auto</b> at any time.</li>
                <li>Click <b>Auto Hold</b> to allow the program to pre-select cards to hold based on Optimal Strategy.</li>
                <li>With <b>Auto Hold</b> you may override the selection of cards and click other cards to drop or hold.</li>
                <li><b>Auto Hold</b> appears in yellow font when active.</li>
                <li>You may gamble a winning game with a "Double or Nothing" round. Press <b>Gamble</b>. In the Double or Nothing game - select a card that is higher than the dealer's card to win. </li>
                <li><b>Gamble</b> is disabled for the Royal Flush jackpot win.</li>
            </ol>

            <h2>Card Ranks</h2>
            <p>Cards are ranked in order from highest to lowest: A, K, Q, J, 10, 9, 8, 7, 6, 5, 4, 3, and 2.</p>

            <section className="handRanks">
                <h2>Overall Hand Ranks</h2>
                <p className="handRoyalFlush"><b>Royal Flush -</b> A, K, Q, J, and 10 of the same suit</p>
                <p className="handStraightFlush"><b>Straight Flush -</b> Five cards of the same suit in sequence - A ranked lowest or highest</p>
                <p className="handFourOfAKind"><b>4 of a Kind -</b> Four cards of the same rank, and 1 other card of a different rank</p>
                <p className="handFullHouse"><b>Full House -</b> Three of a Kind and a Pair</p>
                <p className="handFlush"><b>Flush -</b> Five cards all the same suit</p>
                <p className="handStraight"><b>Straight -</b> Five cards in sequence - A ranked lowest or highest</p>
                <p className="hand3OAK"><b>3 of a Kind -</b> Three cards of the same rank, and 2 other mixed ranked cards</p>
                <p className="hand2Pair"><b>2 Pair -</b> Two different Pairs, with one other card of a different rank as a single kicker</p>
                <p className="handJsOrBetter"><b>Jacks Or Better -</b> Two cards paired that are J or ranked better, and 3 other mixed ranked cards</p>
                <br />
                <p>Only the highest rank pays for any hand.</p>
                <br />
                <p>A hand is played with 1 deck - a card with the same rank and suit cannot appear more than once.</p>
            </section>

            <section className="payoutsSection">
                <h2>Payouts</h2>
                <p>The paytable displayed gives the payout(s) for different hands in coins.</p>
            </section>

            <section className="rtpSection" style={{ display: "block" }}>
                <h2>Return to Player</h2>
                <p>With optimal strategy, the theoretical RTP of this game is 99.54%</p>
            </section>
            <h2>Disconnection Policy</h2>
            <p>
                <span>
                    If connection is lost and the game action was received by the server, the game action will be
                    processed on the server side.
                </span>
                <br />
                <span>
                    If the game state is resume-able the player can continue from after their last action. If the game
                    action was final the resulted game will be visible in Game History only.
                </span>
                <br />
                <span>After 15 days, if not completed, the game will be closed and any further winnings will be forfeited.</span>
            </p>
        </div>
    );






    showHistoryOption = () => (
        <table id="histry_russian">
        <thead id="heading_hstiry">
          <tr>
            <th>Player Cards</th>
            <th>Player Hand</th>
            <th>Bet Amount</th>
            <th>Auto Hold</th>
            <th>Auto Play</th>
            <th>Total Win</th>
          </tr>
        </thead>

        <tbody id="histry_body">
        {this.props.gameState.history.map((obj, i) => (
            <tr>
                <td>
                      {obj.result.cards.map((card_num, index) => {
                        const matchingCard = historyCards.find(res => res.rsc_val === card_num);
                        return (

                          <img
                            className="cardsimages"
                            key={index}
                            src={matchingCard ? matchingCard.src : ''}
                            alt={card_num}
                          />


                        );
                      })}
                </td>
                <td>{obj.result.payoutType}</td>
                <td>{obj.result.betAmount}</td>
                <td>{obj.result.autoHold?"true":"false"}</td>
                <td>{obj.result.autoPlay?"true":"false"}</td>
                <td>{obj.result.winAmount}</td>

            </tr>
        ))}
    
        </tbody>
      </table>
    )

    clickOnAutoholdoption = () => {
        this.playRemainingSounds(this.betchipsnd)
        this.setState((prevState) => ({
            autoHold: !prevState.autoHold
        }))

    }


    callSoundButton = () => {
        this.setState((prevState) =>({
            isSoundOn:!prevState.isSoundOn
        }))

    }





    updateHistory = () => {

    }

    IncreamentBetchips = () => {

        if (this.state.count < 5) {
            this.playPokerChipSound(this.pokerchipsnd);
            this.setState((prevState) => ({ count: prevState.count + 1, }), () => {
                console.log(this.state.count)
                console.log(Number((this.state.coinType * this.chipVal[this.state.count].val).toFixed(2)))
                console.log(this.state.coinType, this.chipVal[this.state.count].val)
                if (this.state.count >= 5) {
                    this.setState({
                        betchipincrementbtn: true, betchipdecreamentbtn: false,
                        betAmount: Number((this.state.coinType * this.chipVal[this.state.count].val).toFixed(2))
                    });
                }
                else {
                    this.setState({
                        betchipdecreamentbtn: false, betchipincrementbtn: false,
                        betAmount: Number((this.state.coinType * this.chipVal[this.state.count].val).toFixed(2))
                    });

                }

            })


        }

    }

    DecreamentBetchips = () => {

        if (this.state.count > 0) {
            this.playPokerChipSound(this.pokerchipsnd);
            this.setState((prevState) => ({ count: prevState.count - 1, }), () => {
                console.log(this.state.count)
                if (this.state.count <= 0) {
                    this.setState({
                        betchipdecreamentbtn: true, betchipincrementbtn: false,
                        betAmount: Number((this.state.coinType * this.chipVal[this.state.count].val).toFixed(2))
                    });
                } else {
                    this.setState({
                        betchipdecreamentbtn: false, betchipincrementbtn: false,
                        betAmount: Number((this.state.coinType * this.chipVal[this.state.count].val).toFixed(2))
                    });
                }
            }
            );

        }


    }

    changeCoinTypeStatus = (incrementNumber) => {
        this.playRemainingSounds(this.betchipsnd)
        const { coinType } = this.state;
        let activeCoinType = coinType;
        if (incrementNumber === 5) {
            activeCoinType = incrementNumber
        } else if (coinType === 5) {
            activeCoinType = 1

        } else {
            activeCoinType = coinType + 1
        }
        this.setState({
            coinType: activeCoinType,
            betAmount: Number((activeCoinType * this.chipVal[this.state.count].val).toFixed(2))
        });
        this.highletPayoutInformation(activeCoinType)

    }



    highletPayoutInformation = id => {
        const betcoinElementstd = document.querySelectorAll(`td`);
        betcoinElementstd.forEach((element) => {
            element.style.backgroundColor = "transparent";
        });
        const betcoinElements = document.querySelectorAll(`.tablecell${id}`);
        betcoinElements.forEach((element) => {
            element.style.backgroundColor = "#ff0000";
        });


    }

    clickOnAutoplayoption = () => {
        this.playRemainingSounds(this.betchipsnd)
        const { showAutoPlayLimits } = this.state;
        let showPlayLimits = !showAutoPlayLimits;
        console.log("showPlayLimits : --", showPlayLimits)
        if (showPlayLimits && this.state.autoPlay === false) {
            this.setState({
                showAutoPlayLimits: true
            }, () => {
                const errorPopupContainerElment = document.getElementById("auroPlayLimitContainer");
                gsap.fromTo(errorPopupContainerElment, { scale: 0, rotateY: 720, opacity: 1 },
                    {
                        scale: 1, duration: 1, rotateY: 0, opacity: 1, ease: "power2.out", onComplete: () => {

                        }
                    })
            })
        } else {

            const body = {
                sessionId: sessionStorage.getItem("sessionId"),
                CN: "JACKS_OR_BETTER_BET",
                object: {
                    gameId: this.props.gameId,
                    action: "STOP_AUTO_PLAY",
                    playerId: Number(this.props.playerId),
                    handId: Number(this.props.gameState.handId),
                    autoHoldCards: false,
                    autoPlayCards: false,
                    coinType: this.state.coinType,
                    coinCount: this.chipVal[this.state.count].val,
                    count: 0,
                    activeRound: 0
                }
            }
            console.log(body);
            this.props.network.sendof(body);


            this.setState({
                autoPlay: false,
                autoHold: false,
                autoPlayFreezButton: true,
                dealDrawBetButton: "Deal",
                dealDrawBetButtonNumber: 0,
            })
        }






    }

    hideAutoPlayLimitPopup = () => {
        this.setState({
            showAutoPlayLimits: false

        })
    }



    autoPlayLimitAmount = (count) => {

        this.setState({
            autoPlayRoundLimit: count,
            showAutoPlayLimits: false
        }, () =>{
                    if (Number((this.state.coinType * this.chipVal[this.state.count].val).toFixed(2)) < this.props.balance) {
            
                        this.clickOnDealButton(true);
                    } else {
            
                        this.showLowBalanceInfoPopup()
                    }

        })

    }



    clickOnDealButton = (autoPlay) => {


        if (Number((this.state.coinType * this.chipVal[this.state.count].val).toFixed(2)) < this.props.balance) {
            if (autoPlay) {
                const { autoPlay, autoHold, autoPlayRoundLimit, activeRound, betAmount } = this.state;
                let presentActiveRound = activeRound + 1;
                console.log("67666", autoPlayRoundLimit >= activeRound, autoPlayRoundLimit, activeRound)

                if (autoPlayRoundLimit >= presentActiveRound) {
                    this.setState((prevState) => ({
                        autoPlay: true,
                        autoHold: true,
                        activeRound: presentActiveRound,
                        betChipsCover: true,
                        betOneHideShowButton: false,
                        betMaxHideShowButton: false,
                        dealDrawHideShowButton: false,
                        autoHoldFreezButton: true,
                        autoPlayFreezButton: true,
                        balanceDeductions: this.props.balance - betAmount
                    }))

                    const body = {
                        sessionId: sessionStorage.getItem("sessionId"),
                        CN: "JACKS_OR_BETTER_BET",
                        object: {
                            gameId: this.props.gameId,
                            action: "PARAM_BET",
                            playerId: Number(this.props.playerId),
                            handId: Number(this.props.gameState.handId),
                            autoHoldCards: true,
                            autoPlayCards: true,
                            coinType: this.state.coinType,               // (1,2,3,4,5)
                            coinCount: this.chipVal[this.state.count].val,              // selectedBetChip
                            count: autoPlayRoundLimit,
                            activeRound: presentActiveRound
                        }
                    }
                    console.log(body);
                    this.props.network.sendof(body);

                } else {
                    this.setState({
                        autoPlay: false,
                        autoHold: false,
                        autoPlayRoundLimit: 0,
                        activeRound: 0,
                        betChipsCover: false,
                        betOneHideShowButton: true,
                        betMaxHideShowButton: true,
                        dealDrawHideShowButton: true,
                        autoHoldFreezButton: false,
                    })
                }




            } else {
                this.playRemainingSounds(this.betchipsnd)
                const body = {
                    sessionId: sessionStorage.getItem("sessionId"),
                    CN: "JACKS_OR_BETTER_BET",
                    object: {
                        gameId: this.props.gameId,
                        action: "PARAM_BET",
                        playerId: Number(this.props.playerId),
                        handId: Number(this.props.gameState.handId),
                        autoHoldCards: this.state.autoHold,
                        autoPlayCards: this.state.autoPlay,
                        coinType: this.state.coinType,               // (1,2,3,4,5)
                        coinCount: this.chipVal[this.state.count].val,  // selectedBetChip
                        count: 0,
                        activeRound: 0
                    }
                }
                console.log(body);
                this.props.network.sendof(body);

                this.setState({
                    dealDrawBetButton: "Draw",
                    dealDrawBetButtonNumber: 1,
                    jacksorbettercards: intialCardStatus,
                    dealDrawHideShowButton: false,
                    betChipsCover: true,
                    betOneHideShowButton: false,
                    betMaxHideShowButton: false,
                    autoPlayFreezButton: true,

                });
            }




            console.log("this.state.numberofrounds", this.state.numberofrounds)
            if (this.state.numberofrounds > 0) {
                this.CardsContainer.current.restartTheGame(intialCardStatus);
            }
            this.setState((prevState) => ({
                numberofrounds: prevState.numberofrounds + 1,
                jacksorbettercards: intialCardStatus,
                balanceDeductions: this.props.balance - this.state.betAmount
            }))




        } else {
            this.showLowBalanceInfoPopup()

        }


    }
    showLowBalanceInfoPopup = () => {
        this.setState({
            errorPopup: true,
            errorPopupTitle: `INSUFFICIENT FUNDS`,
            errorPopupInfo: `Add Funds OR Lower Your Bet Amount.`
        }, () => {
            const errorPopupContainerElment = document.getElementById("errorPopupContainer");
            gsap.fromTo(errorPopupContainerElment, { scale: 0, rotate: 0, opacity: 0 },
                {
                    scale: 1, duration: 1, rotate: 720, opacity: 1, ease: "power2.out", onComplete: () => {

                    }
                });
        })


    }


    hidepopupContainer = () => {

        const errorPopupContainerElment = document.getElementById("errorPopupContainer");
        if (errorPopupContainerElment) {
            gsap.to(errorPopupContainerElment, {
                scale: 0,
                duration: 1,
                rotate: 0,
                opacity: 0,
                ease: "power2.out",

            }
            );
            setTimeout(() => {
                this.setState({
                    errorPopup: false,
                    errorPopupTitle: "",
                    errorPopupInfo: ""
                })
            }, 500)

        }

    }







    dealBetResultRes = (res) => {
        console.log("deal bet result :", res)
        const cardsContainerCoverElement = document.getElementById("cardsContainerCover");
        this.CardsContainer.current.dealBetResultRes(res);


        this.setState({
            dealResult: res
        })

        if (this.state.autoPlay) {
            this.setState({
                autoPlayFreezButton: false,
            })
            setTimeout(() => {
                this.clickOnDrawButton();
            }, 2000);
        } else {
            setTimeout(() => {
                cardsContainerCoverElement.style.display = "none";
                this.setState({
                    dealDrawHideShowButton: true,
                    // dealResult: res

                })
            }, 1000);
        }

    }

    clickOncardToHoldorUnhold = (id) => {

        // this.jacksorbettercards[id].hold = !this.jacksorbettercards[id].hold;
        // console.log(this.jacksorbettercards[id].hold);   
        this.setState((prevState) => ({
            jacksorbettercards: prevState.jacksorbettercards.map((eachCard) => {
                if (eachCard.id === id) {
                    if(!eachCard.hold){
                        this.playRemainingSounds(this.selectCard)
                    }else{
                        this.playRemainingSounds(this.unselectCard)
    
                    }
                    return { ...eachCard, hold: !eachCard.hold }
                }
                return eachCard
            })
        }))
    }

    clickOnDrawButton = () => {
        const { jacksorbettercards ,autoPlay} = this.state;
        if(!autoPlay){
            this.playRemainingSounds(this.betchipsnd)
        }
        let keepCards = [];
        for (let k = 0; k < jacksorbettercards.length; k++) {
            jacksorbettercards[k].hold === true ? keepCards.push(1) : keepCards.push(0);


        }
        const body = {
            sessionId: sessionStorage.getItem("sessionId"),
            CN: "JACKS_OR_BETTER_BET",
            object: {
                gameId: this.props.gameId,
                action: "KEEP_CARDS",
                playerId: Number(this.props.playerId),
                handId: Number(this.props.gameState.handId),
                // cards:[0,0,0,0,0], 1 --> HoldCard , 0-----> ReplaceCard
                cards: keepCards,

            }
        }
        console.log(body);
        this.props.network.sendof(body);

        const cardsContainerCoverElement = document.getElementById("cardsContainerCover");
        cardsContainerCoverElement.style.display = "block";


        this.setState({
            dealDrawBetButton: "Deal",
            dealDrawBetButtonNumber: 0,
            dealDrawHideShowButton: false,

        });



    }

    drawBetResult = (res) => {
        // glowPayoutInfo , winPopuprow
        const { game_PayoutInfo } = this.props

        this.CardsContainer.current.drawUnselectedCards(res, this.state.dealResult, emtyCard);
        let winrowDiv = null;

        setTimeout(() => {
        this.playRemainingSounds(this.playerWin)
        },1000);

        if (res.winAmount > 0) {
            if (res.payoutType) {
                this.setState({
                    winAmount: res.winAmount
                })
                game_PayoutInfo.map((eachPayOut, i) => {
                    if (eachPayOut.key === res.payoutType) {
                        winrowDiv = document.getElementById(`winPopuprow${i}`);
                        winrowDiv.classList.add("glowPayoutInfo");
                    }

                })
                setTimeout(() => {
                    winrowDiv.classList.remove("glowPayoutInfo");
                }, 2500)
            }
        }

        setTimeout(() => {

            this.setState({
                winAmount: 0
            })
            this.updateplayerbalnace();

        }, 2500)

        if (this.state.autoPlay) {

            const { autoPlay, autoHold, autoPlayRoundLimit, activeRound } = this.state;

            if ((autoPlayRoundLimit > activeRound) && this.state.autoPlay) {
                this.setState({
                    autoPlayFreezButton: true,
                })
                setTimeout(() => {
                    this.clickOnDealButton(true);
                }, 3000)
            } else {

                setTimeout(() => {

                    this.setState({
                        autoPlay: false,
                        autoHold: false,
                        autoPlayRoundLimit: 0,
                        activeRound: 0,
                        betOneHideShowButton: true,
                        betMaxHideShowButton: true,
                        autoHoldFreezButton: false,
                        dealDrawHideShowButton: true,
                        autoPlayFreezButton: false,
                        autoPlayRoundLimit: 0,
                        activeRound: 0,
                        betChipsCover: false,
                    })
                }, 2000)


            }

        } else {
            setTimeout(() => {
                this.setState({
                    dealDrawHideShowButton: true,
                    betChipsCover: false,
                    betOneHideShowButton: true,
                    betMaxHideShowButton: true,
                    autoPlayFreezButton: false,
                    autoHoldFreezButton: false,
                    autoPlayRoundLimit: 0,
                    activeRound: 0,
                })
            }, 2000)
        }

    };

    stopAutoPlayResult = (res) => {
        setTimeout(() => {
            this.setState({
                dealDrawHideShowButton: true,
                betChipsCover: false,
                betOneHideShowButton: true,
                betMaxHideShowButton: true,
                autoPlayFreezButton: false,
                autoHoldFreezButton: false,
            })

        }, 2000)
    }

    updateplayerbalnace = () => {

        this.props.gamstateHandle();
        // this.setState({betdeductions:0,noupdateblnce:true})
        const body2 = {
            CN: "GET_PLAYER_INFO",
            object: {
                gameId: Number(this.props.gameId,),
                playerId: Number(this.props.playerId),

            },
            sessionId: sessionStorage.getItem('sessionId'),
        }
        this.props.network.sendof(body2);
        this.body2 = {};

    }


    updatePlayerInfo = (event) => {
        console.log(this.props.balance, event)
        this.setState({
            balanceDeductions: Number(event.balance)
        })

    }

    render() {
        const { game_PayoutInfo } = this.props
        const { count, dealDrawHideShowButton, autoHold, betAmount, autoPlay, winAmount, balanceDeductions ,activeMenuOption,isSoundOn} = this.state
        const { src, val } = this.chipVal[count]
        // console.log(count, src, val, game_PayoutInfo)
        let autoHoldbtn = autoHold ? `${this.state.autoholdButton} borderGlow` : this.state.autoholdButton;
        let autoHoldtext = autoHold ? `chipTextGlow` : "";
        let autoPlaybtn = autoPlay ? `${this.state.autoplayButton} borderGlow` : this.state.autoplayButton;
        let autoPlaytext = autoPlay ? `chipTextGlow` : "";
        let helpOption = activeMenuOption === activeMenuOptionStatus.help? "backgroundColor":"borderColor";
        let historyOption = activeMenuOption === activeMenuOptionStatus.history? "backgroundColor":"borderColor";
        return (
            <div id="jacksorBetterBgContainer">

                <button type="button" id="menuButton" style={this.state.menuButton} onClick={this.showHideMenuOption}>
                    <img id="menuIcon" style={this.state.menuOption} src={menuImg} alt="menuOption" />
                </button>

                <div id="balanceContainer" style={this.state.balanceContainer}>
                    <img id="balanceIcon" style={this.state.userIcon} src={balanceImg} alt="balanceIcon" />
                    <span id="currentBalance" style={this.state.useIdNumber}>{Number(balanceDeductions.toFixed(2))}</span>
                </div>

                <div id="betContainer" style={this.state.betseContainer}>
                    <img id="betIcon" style={this.state.userIcon} src={betImg} alt="betIcon" />
                    <span id="currentBet" style={this.state.useIdNumber}>{Number(betAmount.toFixed(2))}</span>
                </div>

                <div id="winContainer" style={this.state.winseContainer}>
                    <img id="winIcon" style={this.state.userIcon} src={winImg} alt="winImage" />
                    <span id="currentWin" style={this.state.useIdNumber}>{winAmount}</span>
                </div>

                <div id="userContainer" style={this.state.userContainer}>
                    <img id="userIcon" style={this.state.userIcon} src={usernameImg} alt="userIcon" />
                    <span id="currentUserName" style={this.state.userName}>{this.props.user}</span>
                </div>

                <div id="handIdContainer" style={this.state.userIdContainer}>
                    <p>P ID : <span id="" style={this.state.useIdNumber}>{this.props.playerId}</span></p>
                    <p>ID : <span id="" style={this.state.useIdNumber}>{this.props.gameState.handId}</span></p>
                </div>

                {/* <button type="button" style={this.state.informationButtonWnds} id="informationButton">
                    <img id="informationIcon" style={this.state.menuOption} src={informationIcon} alt="informationIcon" />
                </button> */}

                <div id="cardsContainerCover" style={this.state.cardsContainerCover}></div>
                <div id="jacksorbetterTable">
                    <div className={this.state.payoutContainer}>
                        <table className="tableContainer">
                            <tbody>
                                {game_PayoutInfo.map((eachPayOut, i) => {
                                    const { key, value } = eachPayOut
                                    const payAmount = value.split(",")
                                    return (
                                        <>
                                            <div id={`winPopuprow${i}`}></div>
                                            <tr className={`tablerow${i}`}>
                                                <td>{key}</td>
                                                <td className={`tablecell${1}`}>{(payAmount[0]).split(" ")[2]}</td>
                                                <td className={`tablecell${2}`}>{payAmount[1]}</td>
                                                <td className={`tablecell${3}`}>{payAmount[2]}</td>
                                                <td className={`tablecell${4}`}>{payAmount[3]}</td>
                                                <td className={`tablecell${5}`}>{payAmount[4]}</td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <ul style={this.state.CardsContainer}>
                        {this.state.jacksorbettercards.map((eachCard, i) => (
                            <CardsContainer
                                ref={this.CardsContainer}
                                key={i}
                                cardDetails={eachCard}
                                clickOncardToHoldorUnhold={this.clickOncardToHoldorUnhold}
                                isSoundOn={isSoundOn}
                            />
                        ))
                        }
                    </ul>
                </div>

                <button type="button" id="volumeButton" style={this.state.volumeButtonWnds} onClick={this.callSoundButton}>
                    <img id="volumeIcon" style={this.state.menuOption} src={isSoundOn? volumeImg:mutesnds} alt="volumeImg" />
                </button>


                <button disabled={this.state.autoHoldFreezButton} id="autoholdContainer" className={autoHoldbtn} type="button" onClick={this.clickOnAutoholdoption}>
                    {/* <img id="autoholdIcon" src="" /> */}
                    <span id="autoholdText" style={this.state.gameButtonChipText} className={autoHoldtext}>{this.props.language.Auto_Hold}</span>
                </button>



                <button disabled={this.state.autoPlayFreezButton} id="autoplayContainer" className={autoPlaybtn} type="button" onClick={this.clickOnAutoplayoption}>
                    {/* <img id="autoplayIcon" src="" /> */}
                    <span id="autoplayText" style={this.state.gameButtonChipText} className={autoPlaytext}>{autoPlay ? this.props.language.Auto_Stop : this.props.language.Auto_Play}</span>
                </button>


                {this.state.betOneHideShowButton && (<button style={this.state.betButton} type="button" onClick={() => { this.changeCoinTypeStatus(1) }}>
                    <img className= {this.state.betButtonImages} src={buybtnWds} alt="" />
                    <p style={this.state.gameButtonChipText}>{this.props.language.Bet_One}</p>
                </button>)}

                {dealDrawHideShowButton &&

                    // <button disabled={!dealDrawHideShowButton} style={this.state.dealDrawButton} type="button" id="dealDrawButton"
                    <button style={this.state.dealDrawButton} type="button" id="dealDrawButton"
                        onClick={(e) => {
                            e.preventDefault();
                            if (this.state.dealDrawBetButtonNumber === 0) {
                                this.clickOnDealButton(false);

                            } else if (this.state.dealDrawBetButtonNumber === 1) {
                                this.clickOnDrawButton()

                            }
                        }}>
                        <img className= {this.state.betButtonImages} src={betbtnWds} alt="" />
                        <p style={this.state.gameButtonChipText}>{this.state.dealDrawBetButtonNumber === 0 ? this.props.language.Deal : this.props.language.Draw}</p>
                    </button>
                }
                {this.state.betMaxHideShowButton && (<button style={this.state.betMaxButton} type="button" onClick={() => { this.changeCoinTypeStatus(5) }}>
                    <img className= {this.state.betButtonImages} src={foldbtnWds} alt="" />
                    <p style={this.state.gameButtonChipText}>{this.props.language.Bet_Max}</p>
                </button>)}
                <button type="button"></button>
                <button type="button"></button>
                <div id="betchipsContainer" style={this.state.betChipsContainer}>
                    {this.state.betChipsCover && (
                        <div className="betChipscoverContainer"></div>
                    )}
                    <button style={this.state.betChipIncrementButton} disabled={this.state.betchipdecreamentbtn}>
                        <img style={this.state.menuOption} src={betincerementdecrement} id="betincrenet" onClick={(e) => { e.preventDefault(); this.DecreamentBetchips(); }} alt="" />
                    </button>
                    <div id="activeChip" style={this.state.betImageContainer}>
                        <img style={this.state.betChipImage} src={src} id="betchips" alt="" />
                        <span id="" style={this.state.betChipText}>{val}</span>
                    </div>

                    <button style={this.state.betChipDecrementButton} disabled={this.state.betchipincrementbtn}>
                        <img style={this.state.menuOption} src={betincerementdecrement} id="betdecrement" onClick={(e) => { e.preventDefault(); this.IncreamentBetchips(); }} alt="" />
                    </button>
                </div>

                {this.state.errorPopup && (
                    <div className="coverContainer">
                        <div id="errorPopupContainer" className={this.state.errorPopupContainer}>
                            <button className="errorPopupExitIcon" id="exitPopup" onClick={this.hidepopupContainer}><img src={popupExit} alt={popupExit} /></button>
                            <h4 className="errorPopupTitle">{this.state.errorPopupTitle}</h4>
                            <span className="errorPopupInfo">
                                {this.state.errorPopupInfo}
                            </span>
                        </div>
                    </div>
                )}

                {this.state.showAutoPlayLimits && (
                    <div className="coverContainer">
                        <div id="auroPlayLimitContainer" className={this.state.errorPopupContainer}>

                            <button className="errorPopupExitIcon" id="exitPopup" onClick={this.hideAutoPlayLimitPopup}><img src={popupExit} alt={popupExit} /></button>
                            <div className="autoLimitContainer">
                                <button onClick={() => { this.autoPlayLimitAmount(5); }}>AUTO PLAY 5</button>
                                <button onClick={() => { this.autoPlayLimitAmount(10) }}>AUTO PLAY 10</button>
                            </div>

                        </div>
                    </div>

                )

                }
                {this.state.menuOptionContainer && (
                    <>
                    <div className="coverContainer" onClick={this.showHideMenuOption}> </div>
                        <div className={this.state.menuOptionContainerCss}>
                            <button type="button" className={helpOption} onClick={() => { this.chooseAoptionInMenu(activeMenuOptionStatus.help) }}>HELP</button>
                            <button type="button" className={historyOption} onClick={() => { this.chooseAoptionInMenu(activeMenuOptionStatus.history) }}>HISTORY</button>
                        </div>

                        <div className={this.state.helpMainContainer}>
                            {this.showDataBasedActiveMenuOption()}
                        </div>
                   
                    </>

                )

                }



            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        language: state.languageObjs.languageObj
    }
}
export default connect(mapStatesToProps)(JacksorBetter)


//   export default JacksorBetter;
